import React from "react";
import "./open-info.scss";
import FileContainer from "@components/file/file.container";
import CirclePlus from "@components/circle-plus";

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from "react-accessible-accordion";

import { graphql, StaticQuery } from "gatsby";

const OpenInfo = ({ data }) => {
	const { context: { edges: context } = {} } = data;
	const { files: { edges: files } = {} } = data;
	return (
		<section className="full-width open-info">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2>Открытая информация</h2>
					</div>
				</div>
			</div>

			<Accordion
				allowZeroExpanded
				allowMultipleExpanded
				className="accordion accordion_theme_docs"
			>
				{context &&
					context.map(({ node }) => {
						let i = 0;
						const {
							childMarkdownRemark: { frontmatter: { title, docs } = {} } = {}
						} = node;
						return (
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										<div className="grid-container">
											<div className="row">
												<div className="col-sm-1 hidden-md hidden-xs">
													<CirclePlus />
												</div>
												<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-10">
													<div className="container">
														<CirclePlus className="hidden-sm" />
														<span>{title}</span>
													</div>
												</div>
											</div>
										</div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="grid-container">
										<div className="row">
											{docs.map(doc => {
												i += 1;
												const { file: path, name } = doc;
												return (
													<div
														className={`col-xs-12 ${
															i % 2
																? "col-md-offset-2 col-md-4 col-sm-offset-1 col-sm-5"
																: "col-md-4 col-sm-5"
														}`}
													>
														<FileContainer
															className="open-info__file"
															name={name}
															path={path}
															files={files}
														/>
													</div>
												);
											})}
										</div>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
						);
					})}
			</Accordion>
		</section>
	);
};

OpenInfo.defaultProps = {
	data: {}
};

export default props => (
	<StaticQuery
		query={graphql`
			query GetOpenInfoList {
				context: allFile(filter: { relativeDirectory: { eq: "open-info" } }) {
					edges {
						node {
							childMarkdownRemark {
								frontmatter {
									title
									docs {
										file
										name
									}
								}
							}
						}
					}
				}
				files: allFile(
					filter: {
						sourceInstanceName: { eq: "assets" }
						extension: { regex: "/pdf|docx|doc|xlsx|xls/" }
					}
				) {
					edges {
						node {
							name
							relativePath
							prettySize
							root
							sourceInstanceName
							base
							birthTime
							ext
							extension
						}
					}
				}
			}
		`}
		render={data => <OpenInfo data={data} {...props} />}
	/>
);
