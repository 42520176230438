import React from "react";
import { Layout } from "@components";
import OpenInfoPage from "@page-contents/open-info";
import BottomLineWithLink from "@components/bottom-line-with-link";

export default props => (
	<Layout {...props}>
		<OpenInfoPage />
		<BottomLineWithLink title="Стать партнером" to="/became-partner" />
	</Layout>
);
